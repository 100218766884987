//import patternImage from "../img/pattern.png";
import React, { useEffect, useRef } from "react";
import "./Login.css"; // Import the CSS file for styling
import logoImage from "../../img/abrechnungsystemLogos.jpg";

import { FaUser, FaLock } from "react-icons/fa";
import Globe from "@mui/icons-material/Language";

import { useNavigate } from "react-router-dom";
import { Post } from "../../../Common/WebRequest";
import { useState } from "react";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import CookieDialog from "./CookieDialog";
import { clearCacheData } from "../../../Common/Utility";
import createUserSystemLoginDto from "./UserSystemLogin";
const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [userName, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [passwordUser, setPasswordUser] = useState("");

  const [info, setInfo] = useState(
    "Bitte geben Sie Benutzernamen und Passwort ein."
  );
  const [error, setError] = useState("");
  const [wait, setWait] = useState("");
  const [success, setSuccess] = useState("");
  const cookieAgree = JSON.parse(localStorage.getItem("cookieAgreement"));

  clearCacheData();
  useEffect(() => {
    (async () => {
      localStorage.removeItem("login");
    })();
  }, []);

  async function handleUsernameChange(event) {
    setUsername(event.target.value);
    setEmail(event.target.value);
    setFullName(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPasswordUser(event.target.value);
  };

  async function handleSubmit(event) {
    if (!cookieAgree || cookieAgree.isAgree === "") {
      setError(
        "Leider können Sie das System nicht betreten, da Sie der Verwendung von 'Cookies' nicht zugestimmt haben Bitte aktualisieren Sie die Seite und drücken Sie die OK-Schaltfläche, um fortzufahren."
      );
      return;
    }
    event.preventDefault();
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsLoading(false);
    setError("");
    setWait("");
    if (userName === "" || passwordUser === "") {
      setError("'Benutzername' oder 'Passwort' dürfen nicht leer sein.");
      setWait("");
      return;
    }
    setWait("Bitte warten....");

    var password = passwordUser;

    let loginDto = {
      fullName,
      email,
      password,
    };

    let res = await Post("Auth/Login", loginDto);
    if (res.isSuccess) {
      let UserSystemLogins = await createUserSystemLoginDto(loginDto.email);
      UserSystemLogins.regionId = 0;
      if (UserSystemLogins !== null) {
        await Post("UserSystemLogin", UserSystemLogins);
      }
      setError("");
      setWait("");
      setSuccess("OK");
      // see also
      /*localStorage.setItem(
        "login",
        JSON.stringify({
          isLogin: true,
          accessToken: res.result.accessToken,
          refreshToken: res.result.refreshToken,
          userName: res.result.fullName,
          userId: res.result.userId,
          isAdmin: res.result.isAdmin || res.result.userGroup > 10,
          environment: res.result.environment,
          passwordChanged: res.result.passwordChanged,
           
        })
      );*/

      const userData = {
        isLogin: true,
        accessToken: res.result.accessToken,
        refreshToken: res.result.refreshToken,
        userName: res.result.fullName,
        userId: res.result.userId,
        environment: res.result.environment,
        passwordChanged: res.result.passwordChanged,
      };

      // Conditionally add isAdmin to the userData object
      if (res.result.isAdmin) {
        userData.isAdmin = true;
      }

      localStorage.setItem("login", JSON.stringify(userData));

      clearCacheData();
      if (res.result.isAdmin || res.result.userGroup > 10) {
        navigate("/home");
      } else {
        if (res.result.passwordChanged === false) {
          //navigate("/userprofile?isPasswordMustChange=1");
          navigate("/PasswordMustChanged");
        } else navigate("/dashboard");
      }
      navigate(0);

      // window.location.href = "/";
    } else {
      console.log("Error: " + res.Error + ", " + res.ErrorDetail);
      if (res.Error.toLowerCase().includes("failed to fetch")) {
        setError("Bitte überprüfen Sie die Verbindung zum Server.");
      } else {
        if (res.Error.includes("One or more validation errors occurred"))
          setError(res.ErrorDetail);
        else setError(res.Error);
        console.log(res.Error);
      }
      //setError("'Benutzername' oder 'Passwort' ist falsch!");
      setUsername("");
      setPasswordUser("");
      setWait("");
    }
  }
  return (
    <div className="login-container">
      <div className="form-group">
        <div className="form-title">
          <img
            src={logoImage}
            alt="Willkommen zu WebLehrkräfteAbrechnungsystem"
            className="logo"
          />
        </div>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label
              htmlFor="username"
              className={error ? "labelValidation" : "labelStandard"}
            >
              <FaUser className="inputIcon" />
              Benutzername
            </label>
            <input
              autoFocus
              type="text"
              id="username"
              name="username"
              className="input"
              value={userName}
              onChange={handleUsernameChange}
            />
          </div>
          <div className="input-group">
            <label
              htmlFor="password"
              className={error ? "labelValidation" : "labelStandard"}
            >
              <FaLock className="inputIcon" />
              Passwort
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="input"
              value={passwordUser}
              onChange={handlePasswordChange}
            />
          </div>
          <button type="submit" disabled={isLoading} className="button">
            {isLoading ? (
              <ClipLoader
                color="white" // Set the color of the loading animation
                loading={isLoading}
                css={css`
                  display: block;
                  margin: 0 auto;
                `}
              />
            ) : (
              "Anmelden"
            )}
          </button>
          <label
            htmlFor="information"
            className={`labelInfo ${error ? "labelError" : ""} ${
              wait ? "labelWarning" : ""
            }`}
          >
            {error ? error : wait ? wait : info}
          </label>

          <br></br>
          <div>
            <p>
              <a href="passwordrecovery"> Passwort vergessen?</a>
            </p>
            <p>
              <a href="register">Konto erstellen</a>
            </p>
          </div>
          <br></br>
          <div className="flexContainer">
            <Globe className="inputIcon" />
            <p className="paragraph">
              Das System verwendet Standard-Cookies, um den Systembetrieb lokal
              abzuwickeln.
            </p>
          </div>
        </form>
      </div>
      <CookieDialog />

      <div className="footer">
        <p>© 2023 WebLehrkräfteAbrechnungsystem</p>
        <p>Developed By Mohammed Abujayyab</p>
      </div>
    </div>
  );
};

export default Login;
